import React from 'react';
import {Carousel} from 'react-bootstrap';


const HomeSlider = () => {
    return (
        <>
            <Carousel fade={true} indicators={false} controls={false} interval={7000}>
                <Carousel.Item>
                    <div className="homeSlider" style={{backgroundImage: "url('../static/images/slide1.jpg')"}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h2>the totality of existence of innovation</h2>
                                    <h2>in the Web systems design</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                </Carousel.Item>
                <Carousel.Item>
                    <div className="homeSlider" style={{backgroundImage: "url('../static/images/slide2.jpg')"}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h2>the totality of existence of innovation</h2>
                                    <h2>in the edge computing</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="homeSlider" style={{backgroundImage: "url('../static/images/slide3.jpg')"}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h2>the totality of existence of innovation</h2>
                                    <h2>in the computer vision</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </>
    )
};
export default HomeSlider;