import React from "react";
import { compose, withProps } from "recompose";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";
const mapStyles = require("../../static/map/mapStyles.json");
const MyMapComponent = compose(
    withProps({
        /**
         * Note: create and replace your own key in the Google console.
         * https://console.developers.google.com/apis/dashboard
         * The key "AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q" can be ONLY used in this sandbox (no forked).
         */
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyD6OyeSfRSilBTVFPRxPgYvfuPdgBAnQac&callback=initMap",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `450px` }} />,
        mapElement: <div style={{ height: `100%` }} />


    }),
    withScriptjs,
    withGoogleMap
)(props => (
    <GoogleMap defaultZoom={17} defaultCenter={{ lat: 40.188431, lng: 44.517854 }}>
        {props.isMarkerShown && (
            <Marker position={{ lat:40.188431, lng:44.517854 }}  defaultOptions={{styles: mapStyles}} options={{icon:"../../static/images/map-marker.png", styles: require("../../static/map/mapStyles.json")}} />
        )}
    </GoogleMap>
));
export default MyMapComponent;

