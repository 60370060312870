import React from "react";
import {Link} from 'react-router-dom';
function TellYourStory() {
    return (
        <div className="tellUs">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-12">
                        <p>Your time and efforts are priceless to be wasted.</p>
                        <h2>Get from the idea to the solution with us.</h2>
                    </div>
                    <div className="col-lg-4 col-12">
                        <div className="portfolio-btn">
                            <Link to="/Contact" className="tellUs-btn">Tell us your story</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TellYourStory;