import React, {useState, useEffect, useRef} from "react";
import ContactIndex from "../IndexPage/Contact";
import SectionHome from "../HomeSection";
import {useLocation} from 'react-router-dom';

function Contact(props) {

    const nameForm = useRef(null);
    const txtFieldState = {
        value: "",
        valid: true,
        typeMismatch: false,
        errMsg: "" //this is where our error message gets across
    };

    const [formFields, setFormFields] = useState({
        email: {
            ...txtFieldState,
            fieldName: "email",
            required: true,
            requiredTxt: "Email is required",
            formatErrorTxt: "Incorrect email format"
        },
        name: {...txtFieldState, fieldName: "name", required: true, requiredTxt: "Name is required"},
        company: {...txtFieldState, fieldName: "company", required: false, requiredTxt: "Company is required"},
        phone: {...txtFieldState, fieldName: "phone", required: false, requiredTxt: "Phone is required"},
        story: {...txtFieldState, fieldName: "story", required: true, requiredTxt: "Story is required"},
        allFieldsValid: false
    });

    const [emailSent, setEmailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const location = useLocation();
    useEffect(() => {
        if (emailSent) {
            setEmailSent(false);
        }
    }, [location]);

    const reduceFormValues = formElements => {
        const arrElements = Array.prototype.slice.call(formElements); //we convert elements/inputs into an array found inside form element
//we need to extract specific properties in Constraint Validation API using this code snippet
        const formValues = arrElements
            .filter(elem => elem.name.length > 0)
            .map(x => {
                const {typeMismatch} = x.validity;
                const {name, type, value} = x;
                return {
                    name,
                    type,
                    typeMismatch, //we use typeMismatch when format is incorrect(e.g. incorrect email)
                    value,
                    valid: x.checkValidity()
                };
            })
            .reduce((acc, currVal) => { //then we finally use reduce, ready to put it in our state
                const {value, valid, typeMismatch} = currVal;
                const {
                    fieldName,
                    requiredTxt,
                    formatErrorTxt
                } = formFields[currVal.name]; //get the rest of properties inside the state object
//we'll need to map these properties back to state so we use reducer...
                acc[currVal.name] = {
                    value,
                    valid,
                    typeMismatch,
                    fieldName,
                    requiredTxt,
                    formatErrorTxt
                };
                return acc;
            }, {});
        return formValues;
    };


    const checkAllFieldsValid = (formValues) => {
        return !Object.keys(formValues)
            .map(x => formValues[x])
            .some(field => !field.valid);
    };
    const a = 1;
    //console.log('Contact');

    useEffect(() => {

    });

    useEffect(() => {
        window.grecaptcha.ready(() => {
            let badge = document.getElementsByClassName('grecaptcha-badge')[0];
            if (badge) badge.classList.remove('hide');
        });

        return () => {
            let badge = document.getElementsByClassName('grecaptcha-badge')[0];
            if (badge) {
                badge.classList.remove('hide');
                badge.classList.add('hide');
            }
        };
    }, []);

    function sendMessage() {
        setIsLoading(true);
        const form = nameForm.current;
        const formValues = reduceFormValues(form.elements);
        const allFieldsValid = checkAllFieldsValid(formValues);
        allFieldsValid ? window.grecaptcha.execute(window.skey, {action: "contact"})
                .then(function (token) {
                    let data = {
                        Subject: 'site contact',
                        MessageBody: `
                        email: ${form['email'].value}
                        name: ${form['name'].value}
                        company: ${form['company'].value}
                        phone: ${form['phone'].value}
                        story:
                        ${form['story'].value}
                        `,
                        Token: token
                    };

                    fetch(window.fbUrl + '/emailservice/send', {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        method: 'post',
                        body: JSON.stringify(data)
                    })
                        .then(response => {
                            console.log(response);
                            setIsLoading(false);
                            setEmailSent(true);
                            let emailSent = setTimeout(() => {
                                setEmailSent(false)
                            }, 5000)
                        })
                        .catch(error => {
                            console.log(error)
                        });

                })
            :
            setIsLoading(false);
        setFormFields({...formValues, allFieldsValid}); //we set the state based on the extracted values from Constraint Validation API


    }

    const renderEmailValidationError = formFields.email.valid ?
        "" :
        <ErrorValidationLabel
            txtLbl={formFields.email.typeMismatch ? formFields.email.formatErrorTxt : formFields.email.requiredTxt}/>;
    const renderNameValidationError = formFields.name.valid ? "" :
        <ErrorValidationLabel txtLbl={formFields.name.requiredTxt}/>;
    const renderStoryValidationError = formFields.story.valid ? "" :
        <ErrorValidationLabel txtLbl={formFields.story.requiredTxt}/>;

    return (
        <div>
            <SectionHome img="slide1" text1="Bring inspiration and innovation"
                         text2="IN EVERY INDUSTRY"/>
            <section className={`contact ${isLoading ? 'loading blur' : ''}`}>
                <div className="text-center">
                    <h2> Join our <br/> <span>universe</span></h2>
                    <p>Tell us your story. Let us help you with intelligent solution </p>
                </div>
                <div className="container">
                    {emailSent ?
                        <div className="text-center">
                            <h2>Your email was successfully sent!</h2>
                        </div>
                        :
                        <form ref={nameForm} noValidate>
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="mb-4">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                        <span className="input-group-text" id="name">
                                            <img src={require('../../static/images/user.svg')} alt="user"/></span>
                                            </div>
                                            <input type="text" name={"name"}
                                                   className="form-control"
                                                   placeholder="Name*" aria-label="Name"
                                                   aria-describedby="name" required/>
                                            <br/>
                                        </div>
                                        {renderNameValidationError}
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="mb-4">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                    <span className="input-group-text" id="email">
                                         <img src={require('../../static/images/email.svg')} alt="email"/>
                                    </span>
                                            </div>
                                            <input type="email" name={"email"}
                                                   className="form-control"
                                                   placeholder="Email*"
                                                   aria-label="Email"
                                                   aria-describedby="email" required/>
                                        </div>
                                        {renderEmailValidationError}
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="input-group mb-4">
                                        <div className="input-group-prepend">
                                        <span className="input-group-text" id="company">
                                             <img src={require('../../static/images/company.svg')} alt="company"/>
                                        </span>
                                        </div>
                                        <input type="text" name={"company"}
                                               className="form-control" placeholder="Company"
                                               aria-label="Company"
                                               aria-describedby="company"/>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="input-group mb-4">
                                        <div className="input-group-prepend">
                                    <span className="input-group-text" id="phone">
                                         <img src={require('../../static/images/phone.svg')} alt="phone"/>
                                    </span>
                                        </div>
                                        <input type="text" name={"phone"}
                                               className="form-control"
                                               placeholder="Phone"
                                               aria-label="Phone"
                                               aria-describedby="phone"/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-4">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                        <span className="input-group-text" id="story">
                                             <img src={require('../../static/images/pencil.svg')} alt="pencil"/>
                                        </span>
                                            </div>
                                            <textarea className="form-control" name={"story"}
                                                      placeholder="Tell your story*"
                                                      aria-label="Story"
                                                      aria-describedby="story"
                                                      rows="5"
                                                      required/>
                                        </div>
                                        {renderStoryValidationError}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="btn text-uppercase send-btn" onClick={sendMessage}
                                            type={"button"}>Send Message
                                    </button>
                                </div>
                                <div className="col-12" id="captchaPlace">

                                </div>
                            </div>
                        </form>
                    }
                </div>
            </section>
            <ContactIndex/>

        </div>
    )
}

const ErrorValidationLabel = ({txtLbl}) => (
    <p className="text-danger mt-2">
        {txtLbl}
    </p>
);

export default Contact;
