import React, {useState} from "react";
import PortfolioBlock from "./PortfolioBlock";
import json from '../../../static/json/portfolio';
import ContactIndex from "../../IndexPage/Contact";
import SectionHome from "../../HomeSection";

let filterList = [
    {img: "all.svg", imgActive: "allActive.svg", filter: "all"},
    {img: "eshop.svg", imgActive: "shopActive.svg", filter: "eshop"},
    {img: "education.svg", imgActive: "edActive.svg", filter: "education"},
    {img: "science.svg", imgActive: "scienceActive.svg", filter: "science"},
    {img: "retail.svg", imgActive: "retailActive.svg", filter: "retail"},
    {img: "shield.svg", imgActive: "shieldActive.svg", filter: "security"},
    {img: "food.svg", imgActive: "foodActive.svg", filter: "food"},
    {img: "entertainment.svg", imgActive: "entActive.svg", filter: "entertainment"}
];

function Portfolio() {
    const [filtered, setFiltered] = useState('all');

    const filter = (key) => setFiltered(key);


    return (
        <div>
            <SectionHome img="slide1" text1="Bring inspiration and innovation"
                         text2="IN EVERY INDUSTRY"/>
            <section className="portfolio">
                <div className="portfolio-filters bg-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <ul className="list-unstyled">
                                    {filterList.map(item => {
                                        return (
                                            <li className={filtered === item.filter ? "active" : ""}
                                                onClick={() => filter(item.filter)}
                                                key={item.filter}>
                                                <span>
                                                    <img
                                                        src={require(`../../../static/images/filters/${filtered == item.filter ? item.imgActive : item.img}`)}
                                                        alt="all"/>
                                                </span>
                                            </li>
                                        )
                                    })}
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="portfolio-list">
                    <div className="container">
                        <div className="row">
                            {json.portfolioList.filter(item => filtered !== 'all' ? item.filter.includes(filtered) : item).map(item => {
                                return (
                                    <div className="col-lg-6 col-12" key={item.id} data-filter={item.filter}>
                                        <PortfolioBlock id={item.id} title={item.title} text={item.text}
                                                        image={item.image} filter={item.filter}/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
            <ContactIndex/>
        </div>
    )
}

export default Portfolio;