import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRocket, faPhone, faEnvelope, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';

function ContactIndex() {
    return (
        <section id="contact" className="contact-area section-padding section-back-image"
                 style={{backgroundImage: "url(../../static/images/contact-bg.jpg)"}}>
            <div className="container">

                <div className="section-title text-center">
                    <h2 className="section-title-white">Connect with us</h2>
                    <span className="section-title-white-span"> <FontAwesomeIcon icon={faRocket}/> </span>
                    <p className="section-dec-white">Feel free to contact us!</p>
                </div>

                <div className="text-center clearfix">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="single-address">
                                <FontAwesomeIcon icon={faPhone}/>
                                <p>+374 95131105</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="single-address">
                                <FontAwesomeIcon icon={faEnvelope}/>
                                <p>info@innoverse.tech</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="single-address">
                                <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                <p>32, 8 Moskovyan street, Yerevan, RA</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactIndex;
