import React from "react";

function Moves() {
    return (
        <section className="contact-moves">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-12">
                        <h2><b>Tell &#8594; </b></h2>
                        <p> Contact us and describe your idea or project</p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <h2><b>Call &#8594; </b></h2>
                        <p> As we receive your request, we arrange a meeting to discuss the projects details.</p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <h2><b>Get proposal &#8594; </b></h2>
                        <p> We make a research and analyse the project scope, prepare and coordinate the proposal with you
                        </p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <h2><b>Deal &#8594; </b></h2>
                        <p>After the confirmation we  sign the contract and start the project</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Moves;