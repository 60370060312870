import React from 'react';

function AboutSection() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-12">
                    <div className="aboutBlock">
                        <h2>Individual approach:</h2>
                        <p> We listen you. With patience, empathy and experience, we build a vision with you to develop
                            a better solution.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-12">
                    <div className="aboutBlock">
                        <h2>Experience:</h2>
                        <p> Besides our team’s experience in software development, we have years of experience in
                            design and implementation of solutions in different sectors, such as government, ecommerce,
                            retail, healthcare, science and entertainment.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-12">
                    <div className="aboutBlock">
                        <h2>Result:</h2>
                        <p> The result is not only the developed software, but also solutions created exactly for your needs.
                            We always focused on goal achievements </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection;