import React from "react";
import industry from '../../static/images/industry.svg'
import code from '../../static/images/code.svg'
import vision from '../../static/images/vision.svg'

function Statistics() {
    return (
        <div className="statistics">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="statisticsBlock">
                            <img src={code} alt="industry"/>
                            <h2>10+</h2>
                            <h4>In Web & Software <br /> Development</h4>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="statisticsBlock">
                            <img src={vision} alt="industry"/>
                            <h2>6+</h2>
                            <h4>In Computer<br /> Vision</h4>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="statisticsBlock">
                            <img src={industry} alt="industry"/>
                            <h2>10+</h2>
                            <h4>Industries</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Statistics;
