import React from "react";

function SolutionsSection() {
    return (
        <div className="solutions">
            <div className="solutionsTitle">
                <div className="container">
                    <div className="solutionText">
                        <h3>We don't just create softwares.</h3>
                        <h2>We create solutions</h2>
                    </div>
                </div>
            </div>
            <div className="ourSolutions container-fluid">
                <div className="row">
                    <div className="solutionBlock" style={{backgroundImage: 'url(../../static/images/service1.jpg)'}}>
                        <div className="col-lg-6 col-12">
                            <div className="textContainer">
                                <div className="container">
                                    <h2>
                                        Software Development
                                    </h2>
                                    <p>We design small, medium and big custom software systems with high performance and
                                        interactive interface. Based on data processing size in the system a big data
                                        aggregation system can be developed. Systems can be mounted on cloud or on
                                        dedicated servers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="solutionBlock" style={{backgroundImage: 'url(../../static/images/service2.jpg)'}}>
                        <div className="col-lg-6 offset-lg-6 col-12">
                            <div className="textContainer">
                                <div className="container">
                                    <h2>
                                        Artificial Intelligence
                                    </h2>
                                    <p>When the classic programming is not enough to reach the goals, our team can
                                        design cloud and edge computing systems based on deep learning networks, which
                                        will help you in computer vision, object classification, forecasting and
                                        personal assistance.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="solutionBlock" style={{backgroundImage: 'url(../../static/images/service3.jpg)'}}>
                        <div className="col-lg-6 col-12">
                            <div className="textContainer">
                                <div className="container">
                                    <h2>
                                        Computer Vision
                                    </h2>
                                    <p>Computer vision is one of the advanced methods of communicating with the real
                                        world by computer. We design and develop complex and high performance systems
                                        that can be mounted on cloud or on embedded devices.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="solutionBlock" style={{backgroundImage: 'url(../../static/images/service4.jpg)'}}>
                        <div className="col-lg-6 offset-lg-6 col-12">
                            <div className="textContainer">
                                <div className="container">
                                    <h2>
                                        Internet of Things
                                    </h2>
                                    <p>We provide solutions and full stack development of devices and systems for all
                                        types of complexities in requirements. We also provide M2M communication for
                                        compound systems and internal or cloud base control as well as data
                                        analytics.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default SolutionsSection;