import React from 'react';
import ContactIndex from "./Contact";
import Statistics from "./Statistics";
import SolutionsSection from "./Solutions";
import TellYourStory from "./TellYourStory";
import AboutSection from "./AboutSection";
import MyMapComponent from "./Map";
import Moves from "./ContactMoves";
import HomeSection from "./Home";


function IndexPage() {
    return (
        <>
            <HomeSection/>
            <Statistics/>
            <SolutionsSection/>
            <section className="our-culture">
                <div>
                    <h2 className="text-center m-auto">
                        Our culture and values that help our clients <br/>
                        achieve the best results
                    </h2>
                </div>
                <AboutSection/>
            </section>
            <TellYourStory/>
            <Moves/>
            <ContactIndex/>
            <MyMapComponent isMarkerShown/>
        </>
    )
}

export default IndexPage;
