import React from "react";
import {Link} from "react-router-dom";

function PortfolioBlock(props) {
    return (
        <>
            <Link to={`/portfolio/${props.id}`} className="portfolio-block"
                 style={{backgroundImage: `url(../../static/images/portfolio/${props.image})`}}>
                <div className="portfolio-layout">
                    <h2>{props.title}</h2>
                    <p>{props.text}</p>
                </div>
            </Link>
        </>
    )
}

export default PortfolioBlock;
