import React from "react";
import LogoSvg from "../../static/svg/logo";
import {Link} from "react-router-dom";

function Footer() {
    return (
        <footer>
            <div className="container">
               <Link to="/"><LogoSvg/></Link>
                <p>Copyright &copy; {new Date().getFullYear()} INNOVERSE | All rights reserved.</p>
            </div>
        </footer>
    )
}

export default Footer;
