import React, {useEffect} from "react";
import AboutSection from "../IndexPage/AboutSection";
import Statistics from "../IndexPage/Statistics";
import SectionHome from "../HomeSection";

function About() {

    useEffect(() => {
        //console.log('About useEffect');
    }, []);

    return (
        <div>
            <SectionHome img="slide1" text1="Bring inspiration and innovation" text2="IN EVERY INDUSTRY"/>
            <section className="about-page">
                <div className="bg-gray">
                    <Statistics/>
                </div>
                <div className="about-description">
                    <div className="container">
                        <p className="description">Innoverse is a software development company that provides intelligent and
                            innovative solutions in
                            various
                            industries based on cloud, IoT, ai and computer vision technologies. </p>

                        <p className="description"> Our team has more than 10 years of experience in developing web
                            applications of varying complexity
                            and
                            load. And over 6 years of experience in developing computer vision and artificial intelligence
                            applications.</p>
                    </div>
                </div>
                <div className="our-mission bg-gray text-center">
                    <div className="container">
                        <h2 className="title">Our <br/><span>mission</span></h2>
                        <p className="description">Share our universe of knowledge and experience with our clients and work
                            smart everyday to bring
                            idea
                            to next successful story of innovation.</p>
                    </div>
                </div>
                <div className="our-vision text-center">
                    <div className="container">
                        <h2 className="title">Our <br/><span>vision</span></h2>
                        <p className="description">Bring inspiration and innovation to every client.</p>
                    </div>
                </div>
                <div className="culture-section">
                    <h2 className="title">Our <span>culture and values </span>that help our clients achieve the <span>best results</span>
                    </h2>
                    <AboutSection/>
                </div>
            </section>
        </div>
    )
}

export default About;
