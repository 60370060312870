import React, {useState} from "react";
import Lightbox from "react-image-lightbox";

import "react-image-lightbox/style.css";

function Gallery(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    console.log(props, photoIndex);
    return (
        <div className="container gallery section-padding">
            <div  className="thumbnails">
                {props.images && props.images.map((item, index) => (
                    <img key={item} src={require(`../../../static/images/portfolio${item}`)} onClick={() => {
                        setPhotoIndex(index);
                        setIsOpen(true);
                    }} alt="img"/>
                ))}
            </div>
            {!!isOpen && (
                <Lightbox
                    mainSrc={`../../../static/images/portfolio${props.images && props.images[photoIndex]}`}
                    nextSrc={props.images.length > 1 ?`../../../static/images/portfolio${props.images && props.images[(photoIndex + 1) % (props.images && props.images.length)]}` : null}
                    prevSrc={props.images.length > 1 ?`../../../static/images/portfolio${props.images && props.images[(photoIndex + (props.images && props.images.length) - 1) % (props.images && props.images.length)]}` : null}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex(
                            (photoIndex + (props.images && props.images.length - 1)) % (props.images && props.images.length)
                        )
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % (props.images && props.images.length))
                    }
                    enableZoom={false}
                />
            )}
        </div>
    );
}

export default Gallery;
