import React, {useEffect, useState} from "react";
import {useParams, Link} from 'react-router-dom';
import Gallery from "./Gallery";
import json from '../../../static/json/portfolio';
import ContactIndex from "../../IndexPage/Contact";
import SectionHome from "../../HomeSection";


function PortfolioDetails(props) {
    let {id} = useParams();
    //console.log(id)
    const [current, setCurrent] = useState(null);
    useEffect(() => {
        setCurrent(json.portfolioList.find(item => id == item.id));
    }, []);
    return (
        <div>
            <SectionHome img="slide1" text1="Bring inspiration and innovation"
                         text2="IN EVERY INDUSTRY"/>
            <section className="portfolio-details">
                <div className="bg-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="portfolio-title">
                                    <h4><Link to="/portfolio">Portfolio</Link></h4>
                                    <h2>{current && current.title}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container section-padding">
                    <div className="row">
                        <div className="col-lg-6 col-12 pr-lg-0">
                            <div className="portfolio-bg"
                                 style={{backgroundImage: `url(../../../static/images/portfolio/${current && current.image})`}}>
                                <div className="layout p-0">
                                    <a href={current && current.url} target="_blank">
                                        <img src={require('../../../static/images/link.svg')} alt="link"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 pl-lg-0">
                            <div className="bg-gray details-container">
                                <div className="details">
                                    <div className="icon">
                                        <img src="../../../static/images/industryfilter.svg" alt="retail"/>
                                    </div>
                                    <div className="filterName">
                                        {current && (current.industries || current.filter)}
                                    </div>
                                </div>
                                <div className="details">
                                    <div className="icon">
                                        <img src="../../../static/images/area.svg" alt="area"/>
                                    </div>
                                    <div className="fieldName">
                                        {current && current.fields}
                                    </div>
                                </div>
                                <div className="details">
                                    <div className="icon">
                                        <img src="../../../static/images/technologies.svg" alt="technologies"/>
                                    </div>
                                    <div className="techName">
                                        <ul className="list-unstyled">
                                            {current && current.technologies.map(tech => <li key={tech}><img
                                                src={`../../../static/images/service-logos/${tech}.png`} alt={tech}/>
                                            </li>)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="description">
                        <p>{current && current.description}</p>
                    </div>
                </div>
                <div className="portfolio-gallery">
                    <Gallery images={current && current.images}/>
                </div>

            </section>
            <ContactIndex/>
        </div>
    )
}

export default PortfolioDetails;