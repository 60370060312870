import React, {useEffect, useState} from "react";
//import {TransitionGroup, CSSTransition, SwitchTransition} from "react-transition-group";
import "./App.css";
import {Switch, Route, useLocation} from "react-router-dom";
import Header from "./components/Modules/Header";
import {Service} from "./components/Sections/Service";
import Contact from "./components/Sections/Contact";
import IndexPage from "./components/IndexPage/";
import About from "./components/Sections/About";
import Footer from "./components/Modules/Footer";
import Portfolio from "./components/Sections/Portfolio/Portfolio";
import PortfolioDetails from "./components/Sections/Portfolio/PortfolioDetails";


function App() {
    let location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [pageChanged, setPageChanged] = useState(false);
    const [captchaInit, setCaptchaInit] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null);
    let grecaptchaId = null;

    useEffect(() => {
        //console.log('location', location)
        if (isOpen) {
            setIsOpen(false);
        }
        setPageChanged(true);
        window.scrollTo(0, 0);
    },[location]);

    useEffect(() => {
        //console.log('App.js once');
        window.grecaptcha.ready(() => {
            //console.log('grecaptcha.ready', location.pathname);
            if (location.pathname !== "/contact") {
                let badge = document.getElementsByClassName('grecaptcha-badge')[0];
                if (badge) badge.classList.add('hide');
            }
        });
    }, []);

    useEffect(() => {
        //console.log('pageChanged', pageChanged)
        if (pageChanged)
            setPageChanged(false);
    }, [pageChanged]);

    function initCaptcha() {
        if (!captchaInit) {
            setCaptchaInit(true);
            console.log('grecaptcha.ready');
        }
    }

    function errorHandling(err)
    {
        console.err('reCAPTCHA Error:', err)
    }

    function toggleMenu() {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <Header menuIsOpen={isOpen} toggleMenu={toggleMenu} />
            {/*<TransitionGroup component={null}>*/}
            <div className={pageChanged ? '' : 'fade-in'}>
            {/*    <CSSTransition*/}
            {/*        key={location.key}*/}
            {/*        classNames="fade"*/}
            {/*        timeout={800}*/}
            {/*        exit={false}*/}
            {/*    >*/}
                    <Switch>
                        <Route exact path='/'>
                            <IndexPage/>
                        </Route>
                        <Route exact path='/about'>
                            <About/>
                        </Route>
                        <Route exact path='/service'>
                            <Service/>
                        </Route>
                        <Route exact path='/contact'>
                            <Contact onMount={initCaptcha} token={captchaToken} />
                        </Route>
                        <Route exact path='/portfolio'>
                            <Portfolio/>
                        </Route>
                        <Route exact path='/portfolio/:id'>
                            <PortfolioDetails/>
                        </Route>
                    </Switch>
                {/*</CSSTransition>*/}
            </div>
            {/*</TransitionGroup>*/}
            <Footer/>
        </>
    );

}

export default App;
