import React, {useState, useEffect} from "react";
//import logo from 'src/static/images/logo.svg'
import LogoSvg from "../../static/svg/logo";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {NavLink, Link} from 'react-router-dom';
const menuList = [
    {name:"Home", url : "/"},
    {name:"About", url : "/about"},
    {name:"Service", url : "/service"},
    {name:"Portfolio", url : "/portfolio"},
    {name:"Contact", url : "/contact"},
];
function Header(props) {

    const [navClass, setNavClass] = useState("");
    useEffect(() => {
        function onScroll() {
            if (window.scrollY > 100) {
                setNavClass("scrolled")
            } else {
                setNavClass("")
            }
        }
        document.addEventListener('scroll', onScroll);
        return () => {
            document.removeEventListener('scroll', onScroll);
        };
    }, []);

    return (
        <header className={`fixed-top ${navClass}`}>
            <div className="container">
                <nav className="navbar navbar-expand-lg">
                    <Link className="navbar-brand" to="/">
                        <LogoSvg/>
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        onClick={props.toggleMenu}
                        aria-controls="navbarNavAltMarkup"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span>
                            <FontAwesomeIcon icon={faBars}/>
                        </span>
                    </button>
                    <div className={`collapse navbar-collapse ${props.menuIsOpen ?  "show" : "" }`} id="navbarNavAltMarkup">
                        <ul className="navbar-nav">
                            {menuList.map(item =>
                                <li className="nav-item nav-link" key={item.name}>
                                    <NavLink activeClassName='active' exact to={item.url}>
                                        <span>{item.name}</span>
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    )
}
export default Header
