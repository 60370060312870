import React from "react";

function SectionHome(props) {
    return (
        <section className="home" style={{backgroundImage: `url(../static/images/${props.img}.jpg)`}}>
            <div>
                <h4>{props.text1}</h4>
                <h2>{props.text2}</h2>
            </div>
        </section>
    )
}

export default SectionHome;