import React from "react";

function LogoSvg() {
    return (

        <svg version="1.1" className="logoSVG" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             width="200px" height="35px" viewBox="0 0 315 55"
        >
            <path d="M110.59,52.5c-9.48,0-17.2-7.72-17.2-17.2s7.72-17.2,17.2-17.2s17.2,7.72,17.2,17.2S120.07,52.5,110.59,52.5z M110.59,27.26
    c-4.44,0-8.04,3.61-8.04,8.05s3.61,8.04,8.04,8.04c4.44,0,8.05-3.61,8.05-8.04S115.02,27.26,110.59,27.26z M14.13,42.89V18.56H3.64
    v9.15h1.34v15.18H3.64v9.15h11.83v-9.15H14.13z M220.8,22.9v-4.34h-10.49v9.15h1.34v15.18h-1.34v9.15h10.49V32.45l7.41-2.22v6.75
    h9.15V17.93L220.8,22.9z M159.01,18.56l-11.36,21.89l-7.72-14.75l-8.11,4.24l11.56,22.1h8.57l12.62-24.33h6.45v-9.15H159.01z
    M69.57,22.9v-4.34H59.08v9.15h1.34v15.18h-1.34v9.15h10.49V32.45l7.41-2.22v21.81h9.15V17.93L69.57,22.9z M33.87,22.9v-4.34H23.38
    v9.15h1.34v15.18h-1.34v9.15h10.49V32.45l7.41-2.22v21.81h9.15V17.93L33.87,22.9z M9.55,2.5c-2.77,0-5.01,2.24-5.01,5.01
    s2.24,5.01,5.01,5.01s5.01-2.24,5.01-5.01S12.32,2.5,9.55,2.5z M272.46,30.13c-3.12-2.32-7.03-3.01-10.75-1.89l-6.73,2.02
    c-0.16,0.05-0.32,0.07-0.49,0.07c-0.94,0-1.71-0.77-1.71-1.71c0-0.94,0.77-1.71,1.71-1.71c0.49,0,0.84,0.2,1.05,0.36l5.67-7.19
    c-1.9-1.5-4.29-2.33-6.72-2.33c-5.99,0-10.86,4.87-10.86,10.86s4.87,10.86,10.86,10.86c1.06,0,2.11-0.15,3.12-0.46l6.73-2.02
    c0.92-0.28,1.89-0.11,2.66,0.47c0.77,0.57,1.21,1.45,1.21,2.41c0,1.66-1.35,3.01-3.01,3.01h-17v9.15h17
    c6.71,0,12.16-5.46,12.16-12.16C277.36,36,275.58,32.44,272.46,30.13z M193.59,42.65l1.36-0.41c5.12-1.55,8.57-6.18,8.57-11.53
    c0-6.7-5.45-12.15-12.15-12.15h-12.01v5.26c-2.83,3-4.58,7.04-4.58,11.48c0,9.23,7.51,16.74,16.74,16.74h9.08v-9.15h-7.02V42.65z
    M183.98,35.99c-0.02-0.23-0.03-0.46-0.03-0.69c0-4.18,3.25-7.59,7.43-7.59c1.65,0,2.99,1.34,2.99,2.99c0,1.29-0.83,2.4-2.06,2.77
    L183.98,35.99z M301.44,42.65l1.36-0.41c5.12-1.55,8.57-6.18,8.57-11.53c0-6.7-5.45-12.15-12.15-12.15h-12.01v5.26
    c-2.83,3-4.58,7.04-4.58,11.48c0,9.23,7.51,16.74,16.74,16.74h9.08v-9.15h-7.02V42.65z M291.82,35.99
    c-0.02-0.23-0.03-0.46-0.03-0.69c0-4.18,3.25-7.59,7.43-7.59c1.65,0,2.99,1.34,2.99,2.99c0,1.29-0.83,2.4-2.06,2.77L291.82,35.99z"
            />
        </svg>
    )
}

export default LogoSvg;